/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h5: "h5"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Unbeknownst to many, Microsoft released a patch in August for one of the most severe bugs ever reported to the company. The bug, located in MS-NRPC (a core component of Active Directory), is called Zerologon. It would allow an attacker with a foothold on your internal network to essentially become a Domain Admin with one click. All that is required is for a connection to the Domain Controller to be possible from the attacker’s viewpoint. Read more about how it works below."), "\n", React.createElement(_components.p, null, "As a valued Vigilant customer, we want to notify you of this security risk and we strongly urge you to read Microsoft's guidance on ", React.createElement(_components.a, {
    href: "https://cybersecurity.vigilantnow.com/e3t/Btc/LY+113/cXjwj04/VVytJt8xJjCfW3MpsGq3SHZLfW1CR2894Db0jqN37Jq5B9kJWfV7Wycr7CgTLyW3J2WMZ7_q2QCW4CL5YQ7GN6-nW7-w1841-3f6_W99MJBq5wpCy0VsqWXY20GKBwW9g-ryQ6Zd4YXM6HVGDwnnp4W3gSh3g6SkKpHW4t0ST67J7MyLW3g911r5QxrL-W4yjsTd6XfJV6W2c7z-j66F_nmW725r5Z2KXPdDW6-j1M25mKXdYW19yT1N5nZM7bW6T1rMJ1qJ7sqW2_bSvg2Cs0LZW1r4hLT4fyq2NW2R5ffz1MwtrhW3nbmDr5Sc5BnW4kJJJw6H1X9rVCBq6w8mwjhnW6RjDt16NWp2vW6nq-rs2XVqVVW1xVn2f2jjWQzW3h-cgj29CRYyW7KVJ-V7yWfgxW7MgfrD6xXPWwW9f1CpK6-pRwVW3jL1wH42RX9MW94nBKF1t7VYNW9hS5332GQB2mW1SCLy12TrgzlW4yv_5n3jR3y2W5bsMXx4f0_1zW7lGpZl42dGpxW4Jtk8Z5XX777W1fSLpN1D2PGgW1-Y6-Y4_k0B0W3hTMQ08C3YH0N1gT16rpx5zdW1xc5rr8l6bZSW5XGdcN2lP4MKW5_r3R18gQJPgN51923fwmcXWW1XYn_K5_cvKQW4sXJZT25CnJrW1_6Ch96zTzk1W30-Np34H1FdpW2gPCn94w17vzVqG7cl5T84rgN3H4TV1__-g_W18db0T59mNQYN58PNHlYfq83W2D76S_5LPkZBW9lZhZs3-1bLFW6-6qL06j7M-hW5GW3cK4nP8Z8N7qsrffQHYtYVJnr8z4QrFG0W4k7D168yHlRzW4TBrH_98MwVCW3G1L6c8pDJK1W6mkjT61r2N3PW1vvsmy5N1VBrW4PMwST5ygLzwW2N7jx2600HVfW4VkZ1m5V_TDKW3jCrK_9jnkK_W5Xvw4r3f6ZP0W5r6SkH3r7CBKW7BThsH784y4G3fhW1"
  }, "CVE-2020-1472"), " and install the patch."), "\n", React.createElement(_components.p, null, "If you're a CyberDNA® customer, our hunt team has created and deployed detection for this vulnerability. We will continue to monitor this threat and enhance our detection as needed. You can rest assured that we've got your back. If you're a MEP customer only, see below for how you can increase your security by installing CyberDNA®."), "\n", React.createElement(_components.p, null, "As always, thank you for being a Vigilant customer and trusting us to protect your business. If you have any questions, please don't hesitate to ask by emailing CRS@vigilantnow.com."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cybersecurity.vigilantnow.com/e3t/Btc/LY+113/cXjwj04/VVytJt8xJjCfW3MpsGq3SHZLfW1CR2894Db0jqN37Jq5B9kJWfV7Wycr7CgTLyW3J2WMZ7_q2QCW4CL5YQ7GN6-nW7-w1841-3f6_W99MJBq5wpCy0VsqWXY20GKBwW9g-ryQ6Zd4YXM6HVGDwnnp4W3gSh3g6SkKpHW4t0ST67J7MyLW3g911r5QxrL-W4yjsTd6XfJV6W2c7z-j66F_nmW725r5Z2KXPdDW6-j1M25mKXdYW19yT1N5nZM7bW6T1rMJ1qJ7sqW2_bSvg2Cs0LZW1r4hLT4fyq2NW2R5ffz1MwtrhW3nbmDr5Sc5BnW4kJJJw6H1X9rVCBq6w8mwjhnW6RjDt16NWp2vW6nq-rs2XVqVVW1xVn2f2jjWQzW3h-cgj29CRYyW7KVJ-V7yWfgxW7MgfrD6xXPWwW9f1CpK6-pRwVW3jL1wH42RX9MW94nBKF1t7VYNW9hS5332GQB2mW1SCLy12TrgzlW4yv_5n3jR3y2W5bsMXx4f0_1zW7lGpZl42dGpxW4Jtk8Z5XX777W1fSLpN1D2PGgW1-Y6-Y4_k0B0W3hTMQ08C3YH0N1gT16rpx5zdW1xc5rr8l6bZSW5XGdcN2lP4MKW5_r3R18gQJPgN51923fwmcXWW1XYn_K5_cvKQW4sXJZT25CnJrW1_6Ch96zTzk1W30-Np34H1FdpW2gPCn94w17vzVqG7cl5T84rgN3H4TV1__-g_W18db0T59mNQYN58PNHlYfq83W2D76S_5LPkZBW9lZhZs3-1bLFW6-6qL06j7M-hW5GW3cK4nP8Z8N7qsrffQHYtYVJnr8z4QrFG0W4k7D168yHlRzW4TBrH_98MwVCW3G1L6c8pDJK1W6mkjT61r2N3PW1vvsmy5N1VBrW4PMwST5ygLzwW2N7jx2600HVfW4VkZ1m5V_TDKW3jCrK_9jnkK_W5Xvw4r3f6ZP0W5r6SkH3r7CBKW7BThsH784y4G3fhW1"
  }, "Read Guidance Now")), "\n", React.createElement(_components.h5, null, "How Zerologon Works"), "\n", React.createElement(_components.p, null, "Threat actors can exploit Netlogon, a Windows Server process that authenticates users and other services within a domain. Unless it is halted manually or by a runtime error, Netlogon continuously runs in the background, because it is an application, and not a service. Netlogon can be stopped or restarted from the command-line terminal. All that is required is for a connection to the Domain Controller to be possible from the attacker’s viewpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://cybersecurity.vigilantnow.com/"
  }, "Read Guidance Now")), "\n", React.createElement(_components.h5, null, "Why is this important?"), "\n", React.createElement(_components.p, null, "If you have a threat actor inside your environment, they could take over your entire corporate network in about three seconds. This “elevation of privilege” vulnerability is so severe that the U.S. government is requiring all federal agencies to patch it by Monday. NIST rated Zerologon with a CVSS score of 10.0 in the National Vulnerability Database (NVD)."), "\n", React.createElement(_components.h5, null, "Interested in CyberDNA®?"), "\n", React.createElement(_components.p, null, "Did you know Vigilant is currently offering a free trial of CyberDNA®? Through a comprehensive, five-day security threat audit that deploys CyberDNA® in your network, you can experience the confident security of being a Vigilant customer. CyberDNA® defends against the threats that penetrate your endpoint defenses, identifying and containing threats within minutes to hours*. But, hurry! It's a 30-day limited-time offer."), "\n", React.createElement(_components.p, null, "*The 2020 industry average for a breach lifecycle for companies deploying competitive solutions is 315 days, or roughly ten months."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
